.kTlqin {
  background-color: rgb(255, 255, 255);
}
@media (max-width: 990px) {
  .kTlqin {
    background-color: #ffffff;
  }
}

.kTlqin .reuseModalHolder {
  padding: 0;
  overflow: auto;
  border-radius: 3px 3px 0 0;
  border: 0;
} /*!sc*/
data-styled.g123[id="layoutstyle__LayoutWrapper-sc-1bo13ya-0"] {
  content: "kTlqin,";
} /*!sc*/
